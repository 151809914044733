export const appConfig = {
  appName: 'The Gateway',
  appSettings: {
    enableNewAndUnfinishedFeatures: true, // use this flag for development to turn features on in your code but set to false if deploying to other environments where you don't want functionality to show
    parklandEmailDomain: '@parkland.ca'
  },
  appVersion: '2.9.0',
  caching: {
    stationCachingHours: process.env.REACT_APP_CACHING_HOURS_STATIONS,
    userCachingMinutes: process.env.REACT_APP_CACHING_MINUTES_USER,
    gatewayStations: 'gateway.stations',
    gatewayUser: 'gateway.user',
    contentfulRegions: 'contentful.regions',
    contentfulBrands: 'contentful.brands',
    contentfulClassOfTrades: 'contentful.classOfTrades'
  },
  contentful: {
    classOfTrades: {
      coro: 'CORO',
      cosa: 'COSA',
      dodo: 'DODO',
      rdoc: 'RDOC',
      otrFranchise: 'OTR Franchise',
      otrDealer: 'OTR Dealer',
      dealer: 'Dealer'
    },
    errorsMessages: {
      resourceNotFound: 'The resource could not be found'
    }
  },
  commissionPortal: {
    baseAPIURL: 'https://api-gasprices.parkland.ca/DealerPortalApi/api',
    endPoints: {
      stationRiskSharing: {
        uri: '',
        query: {
          queryString: '',
          paramSiteId: '[SITEID]',
          paramLanguage: '[LANG]',
        }
      },
      dealerCommissionReport: {
        uri: 'Dealer/CommissionReport',
        query: {
          queryString: '?siteId=[SITEID]&language=[LANG]&startDate=[STARTDATE]&endDate=[ENDDATE]',
          paramSiteId: '[SITEID]',
          paramLanguage: '[LANG]',
          paramStartDate: '[STARTDATE]',
          paramEndDate: '[ENDDATE]',
        }
      }
    }
  },
  cookies: {
    userActivityExpireHours: Number(process.env.REACT_APP_USER_ACTIVITY_EXPIRE_HOURS),
    userActivityDateTime: 'user.activityDateTime',
    userHasLoggedOut: 'user.hasLoggedOut',
    userHasLoggedIn: 'user.hasLoggedIn',
    userIsNavigatingBack: 'user.isNavigatingBack',
    latestUpdatesSelectedDateRangeType: 'latestUpdates.selectedRangeType',
    latestUpdatesSelectedStartDate: 'latestUpdates.selectedStartDate',
    latestUpdatesSelectedEndDate: 'latestUpdates.selectedEndDate'
  },
  data: {
    dataSource: process.env.REACT_APP_DATA_SOURCE,
    dataSourceReal: 'real',
    dataSourceFake: 'fake'
  },
  email: {
    helpEmailTo: process.env.REACT_APP_HELP_TO_EMAIL,
    helpEmailFrom: process.env.REACT_APP_HELP_TO_EMAIL
  },
  environments: {
    builds: {
      production: 'production',
      development: 'development'
    },
    dev: {
      hostName: 'dev.thegateway.parkland.ca',
      contentfulEnvironment: 'DEV'
    },
    test: {
      hostName: 'test.thegateway.parkland.ca',
      contentfulEnvironment: 'QA'
    },
    ppd: {
      hostName: 'ppd.thegateway.parkland.ca',
      contentfulEnvironment: 'UAT'
    },
    prod: {
      hostName: 'thegateway.parkland.ca',
      contentfulEnvironment: 'master'
    },
  },
  errors: {
    lastSystemErrorKey: 'last-system-error'
  },
  i18n: {
    actions: {
      refreshNotifications: 'RefreshNotifications',
      account: 'Account',
      notifications: 'Notifications',
      station: 'Station',
      help: 'Help',
      checklist: 'Checklist',
      language: 'Language',
      logout: 'Logout',
      search: 'Search',
      submit: 'Submit',
      saveChanges: 'SaveChanges',
      savePriceSurvey: 'SavePriceSurvey',
      refreshStations: 'RefreshStations',
      returnToDefaultView: 'ReturnToDefaultView',
      downloadReport: 'DownloadReport',
      stationsWhoReadArticleReport: 'StationsWhoReadArticleReport',
      stationsWhoDidntReadArticleReport: 'StationsWhoDidntReadArticleReport',
      stationsWhoAcknowledgedArticleReport: 'StationsWhoAcknowledgedArticleReport',
      stationsWhoDidntAcknowledgeArticleReport: 'StationsWhoDidntAcknowledgeArticleReport',
      cancel: 'Cancel',
      clear: 'Clear',
      download: 'Download',
      exportToExcel: 'ExportToExcel',
      viewReport: 'ViewReport',
      searchDateRange: 'SearchDateRange',
      shareArticle: 'ShareArticle',
      viewUserActivityMetadata: 'ViewUserActivityMetadata',
      backToHome: 'BackToHome',
      downloadPDFReport: 'DownloadPDFReport',
      openFileDialog: 'OpenFileDialog',
      addNewUser: 'AddNewUser',
      addUser: 'AddUser',
      disableUser: 'DisableUser',
      enableUser: 'EnableUser',
      updateUser: 'UpdateUser',
      deleteUser: 'DeleteUser',
      deleteUserPermanently: 'Delete',
      disableUserPermanently: 'Disable',
      selectfiles: 'SelectFiles',
      submitAServiceTicket: 'SubmitAServiceTicket',
      updatePromoCode: 'UpdatePromoCode',
      downloadMysteryShopPDF: 'DownloadMysteryShopPDF',
      viewFuelPrice: 'ViewFuelPrice',
      refresh: 'Refresh',
      viewingUpdatesBetween: 'ViewingUpdatesBetween',
      viewingUpdatesInTheLast: 'ViewingUpdatesInTheLast',
      days: 'Days',
      and: 'And'
    },
    application: {
      appName: 'AppName',
    },
    email: {
      helpEmailSubject: 'HelpEmailSubject',
      sharedArticleWithYou: 'SharedArticleWithYou'
    },
    formFields: {
      firstName: 'FirstName',
      lastName: 'LastName',
      email: 'Email',
      city: 'City',
      language: 'Language',
      superAdmin: 'SuperAdmin',
      admin: 'Admin',
      tm: 'TM',
      preferredStation: 'PreferredStation',
      timezone: 'Timezone',
      tellUsMore: 'TellUsMore',
      userSettings: 'UserSettings',
      preferences: 'Perferences',
      titleTableHeader: 'TitleTableHeader',
      dateModifedTableheader: 'DateModifedTableheader',
      favouriteTableHeader: 'FavouriteTableHeader',
      shelfSizeTableHeader: 'ShelfSizeTableHeader',
      downloadTableHeader: 'DownloadTableHeader',
      tags: 'Tags',
      selectStation: 'Select',
      stationPicker: 'StationPicker',
      manageStations: 'ManageStations',
      filterPlanoGramsByTag: 'FilterPlanoGramsByTag',
      viewAllArticles: 'ViewAllArticles',
      viewAllCategories: 'ViewAllCategories',
      currentlySelectedStation: 'CurrentlySelectedStation',
      view: 'View',
      detailRequestSpecific: 'DetailRequestSpecific',
      emailMeACopy: 'EmailMeACopy',
      stationPickerOptions: 'StationPickerOptions',
      categoryTableHeader: 'Category',
      userTableHeader: 'UserTableHeader',
      emailTableHeader: 'EmailTableHeader',
      superTableHeader: 'SuperTableHeader',
      adminTableHeader: 'AdminTableHeader',
      tmTableHeader: 'TMTableHeader',
      siteDescription: 'SiteDescription',
      selection: 'Selection',
      siteID: 'SiteID',
      stationName: 'StationName',
      date: 'Date',
      allStations: 'AllStations',
      selectedStations: 'SelectedStations',
      filter: 'Filter',
      mdmAssignedStations: 'MDMAssignedStations',
      stations: 'Stations',
      selected: 'Selected',
      clearSelection: 'ClearSelection',
      appVersion: 'AppVersion',
      appEnvironment: 'AppEnvironment',
      shelfSize: 'ShelfSize',
      updatedDate: 'UpdatedDate',
      none: 'None',
      section: 'Section',
      previousScore: 'PreviousScore',
      currentScore: 'CurrentScore',
      difference: 'Difference',
      bu: 'BU',
      documentDate: 'DocumentDate',
      province: 'Province',
      fuelBrand: 'FuelBrand',
      operatingModel: 'OperatingModel',
      rom: 'ROM',
      overallScore: 'OverallScore',
      interiorScore: 'InteriorScore',
      exteriorScore: 'ExteriorScore',
      washroomScore: 'WashroomScore',
      customerService: 'CustomerService',
      loyaltyAsk: 'LoyaltyAsk',
      loyaltyOffer: 'LoyaltyOffer',
      upsellProduct: 'UpsellProduct',
      recordsReceived: 'RecordsReceived',
      averageScore: 'AverageScore',
      averageScorePercentage: 'AverageScorePercentage',
      numberOfMysteryShops: 'NumberOfMysteryShops',
      searchColumn: 'SearchColumn',
      mysteryShopperReportFileName: 'MysteryShopperReportFileName',
      period: 'Period',
      product: 'Product',
      ssPrice: 'SSPrice',
      fsPrice: 'FSPrice',
      ssMargin: 'SSmargin',
      fsMargin: 'FSMargin',
      startDate: 'StartDate',
      endDate: 'EndDate',
      station: 'Station',
      riskSharing: 'RiskSharing',
      updates7Days: 'Updates7Days',
      updates15Days: 'Updates15Days',
      updates30Days: 'Updates30Days',
      dateRange: 'DateRange',
      activityType: 'ActivityType',
      pageName: 'PageName',
      metadata: 'Metadata',
      viewingNumberOfStations: 'ViewingNumberOfStations',
      toName: 'ToName',
      toEmail: 'ToEmail',
      emailMessage: 'EmailMessage',
      subject: 'Subject',
      fileName: 'FileName',
      planogramName: 'PlanogramName',
      recordCount: 'RecordCount',
      searchTerm: 'SearchTerm',
      helpTopic: 'HelpTopic',
      articleName: 'ArticleName',
      articleID: 'ArticleID',
      reportName: 'ReportName',
      error: 'Error',
      dropOrSelectFiles: 'DropOrSelectFiles',
      selectedFiles: 'SelectedFiles',
      phone: 'Phone',
      fromEmail: 'FromEmail',
      filesSelected: 'FilesSelected',
      fileBytes: 'FileBytes',
      otrPromoOptions: 'OTRPromoOptions',
      otrPromoOptionA: 'OTRPromoOptionA',
      otrPromoOptionB: 'OTRPromoOptionB',
      otrPromoOptionC: 'OTRPromoOptionC',
      otrPromoOptionD: 'OTRPromoOptionD',
      otrArticle: 'OTRArticle',
      ageRestrictedShopsCount: 'AgeRestrictedShopsCount',
      mysteryShopPassRate: 'MysteryShopPassRate',
      shopType: 'ShopType',
      mysteryShop: 'MysteryShop',
      ageRestricted: 'AgeRestricted',
      tmAudit: 'TMAudit',
      averageForMysteryShops: 'AverageForMysteryShops',
      averageForAgeRestrictedShops: 'AverageForAgeRestrictedShops',
      priceChangeType: 'PriceChangeType',
      effectiveDate: 'EffectiveDate',
      fullPrice: 'FullPrice',
      fuelType: 'FuelType',
      buySellModels: 'BuySellModels',
      theReportWillGoToYourTM: 'TheReportWillGoToYourTM',
      theSurveyWillTakeApproximately: 'TheSurveyWillTakeApproximately',
      pleaseCompleteAllAsApplicable: 'PleaseCompleteAllAsApplicable',
      digitJDEStation: 'DigitJDEStation',
      emailAddress: 'EmailAddress',
      runoutStartDate: 'RunoutStartDate',
      runoutStartTime: 'RunoutStartTime',
      runoutEndDate: 'RunoutEndDate',
      runoutEndTime: 'RunoutEndTime',
      productOut: 'ProductOut',
      territoryManager: 'TerritoryManager',
      didDowngradeOccur: 'DidDowngradeOccur',
      numberOfLitresDowngraded: 'NumberOfLitresDowngraded',
      commentsIfRunoutDowngrade: 'CommentsIfRunoutDowngrade',
      submit: 'Submit',
      status: 'Status',
      thisIsARequiredField: 'ThisIsARequiredField'
    },
    languages: {
      contentfulEnglish: 'en-US',
      contentfulFrench: 'fr-CA',
      i18nEnglish: 'en',
      i18nFrench: 'fr'
    },
    messages: {
      noOTRArticles: 'NoOTRArticles',
      noTermsAndConditions: 'NoTermsAndConditions',
      couldNotLoadSearchResults: 'CouldNotLoadSearchResults',
      NoAnnouncements: 'NoAnnouncementsMessage',
      NoCriticalUpdates: 'NoCriticalUpdatesMessage',
      noUpdatesInSelectedTimeframeMessage: 'NoUpdatesInSelectedTimeframeMessage',
      HappeningToday: 'HappeningTodayMessage',
      ArticleNotFound: 'ArticleNotFound',
      Favourite: 'Favourite',
      Unfavourite: 'Unfavourite',
      GoodMorning: 'GoodMorning',
      GoodAfternoon: 'GoodAfternoon',
      GoodEvening: 'GoodEvening',
      ArticlesInCategoryNotFound: 'ArticlesInCategoryNotFound',
      AcknowledgeableArticlesInCategoryNotFound: 'AcknowledgeableArticlesInCategoryNotFound',
      ArticlesInSubcategoryNotFound: 'ArticlesInSubcategoryNotFound',
      SubcategoriesInCategoryNotFound: 'SubcategoriesInCategoryNotFound',
      Other: 'Other',
      UserInformationSaved: 'UserInformationSaved',
      UnableToSaveInformation: 'UnableToSaveInformation',
      UserSettingsSaved: 'UserSettingsSaved',
      UnableToSaveSettings: 'UnableToSaveSettings',
      UnableToSaveSurvey: 'UnableToSaveSurvey',
      UnableToSaveEmptySurvey: 'UnableToSaveEmptySurvey',
      FeatureNotActive: 'FeatureNotActive',
      NetworkIssueMessage: 'NetworkIssueMessage',
      ArticleFavourited: 'ArticleFavourited',
      ArticleUnfavourited: 'ArticleUnfavourited',
      CouldNotUpdateFavourite: 'CouldNotUpdateFavourite',
      couldNotGetFavourites: 'CouldNotGetFavourites',
      couldNotGetArticle: 'CouldNotGetArticle',
      articleAcknowledged: 'ArticleAcknowledged',
      couldNotUpdateArticleAcknowledgedStatus: 'CouldNotUpdateArticleAcknowledgedStatus',
      HelpMessageSent: 'HelpMessageSent',
      CouldNotSendHelpMessage: 'CouldNotSendHelpMessage',
      CouldNotSendEmail: 'CouldNotSendEmail',
      NoArticlesFound: 'NoArticlesFound',
      NoFormsFound: 'NoFormsFound',
      NoLinksFound: 'NoLinksFound',
      noPlanogramsFound: 'NoPlanogramsFound',
      NoFAQsFound: 'NoFAQsFound',
      NoContactsFound: 'NoContactsFound',
      noFavouritedArticlesFound: 'NoFavouritedArticlesFound',
      noFavouritedPlanoGramsFound: 'NoFavouritedPlanoGramsFound',
      noStationsAssigned: 'NoStationsAssigned',
      lookingForSomething: 'LookingForSomething',
      searchInstructions: 'SearchInstructions',
      weCouldNotFindArticlesFor: 'WeCouldNotFindArticlesFor',
      weCouldNotFindPlanogramsFor: 'WeCouldNotFindPlanogramsFor',
      found: 'Found',
      resultsForSearch: 'ResultsForSearch',
      stationChanged: 'StationChanged',
      helpNeed: 'HelpNeed',
      helpSelectTopic: 'helpSelectTopic',
      NoArticleSelected: 'NoArticleSelected',
      viewingArticle: 'ViewingArticle',
      otherStationsFound: 'OtherStationsFound',
      stationsFound: 'StationsFound',
      couldNotGetStations: 'CouldNotGetStations',
      couldNotGetArticleCategory: 'CouldNotGetArticleCategory',
      couldNotGetForms: 'CouldNotGetForms',
      couldNotGetLinks: 'CouldNotGetLinks',
      couldNotGetPlanograms: 'CouldNotGetPlanograms',
      couldNotGetContacts: 'CouldNotGetContacts',
      couldNotGetFAQs: 'CouldNotGetFAQs',
      couldNotGetHelpTopics: 'CouldNotGetHelpTopics',
      iHaveReadAndUnderstoodArticle: 'IHaveReadAndUnderstoodArticle',
      youHaveReadAndUnderstoodArticle: 'YouHaveReadAndUnderstoodArticle',
      cantSeeTheStationsLookingFor: 'CantSeeTheStationsLookingFor',
      noDownloadsForPlanogram: 'NoDownloadsForPlanogram',
      planogramFavourited: 'PlanogramFavourited',
      planogramUfavourited: 'PlanogramUnfavourited',
      youCanNowSeeAllContent: 'YouCanNowSeeAllContent',
      stationsRefreshed: 'StationsRefreshed',
      internalServerError: 'InternalServerError',
      genericIntenralServerMessage: 'GenericIntenralServerMessage',
      localWeather: 'LocalWeather',
      couldNotGetLocalWeather: 'CouldNotGetLocalWeather',
      browserDoesNotSupportVideo: 'Your browser does not support video | Votre navigateur ne prend pas en charge la vidéo',
      userNotinitialised: 'UserNotInitialised',
      siteNotConfiguredCorrectly: 'SiteNotConfiguredCorrectly',
      noStationSelected: 'NoStationSelected',
      noStationFound: 'NoStationFound',
      reasonNoSelectedStation: 'ReasonNoSelectedStation',
      reasonEmptyRegion: 'ReasonEmptyRegion',
      reasonIllCnfiguredRegion: 'ReasonIllCnfiguredRegion',
      reasonEmptyOperatingModel: 'ReasonEmptyOperatingModel',
      reasonIllConfiguredOperatingModel: 'ReasonIllConfiguredOperatingModel',
      reasonEmptyBrand: 'ReasonEmptyBrand',
      reasonIllConfiguredBrand: 'ReasonIllConfiguredBrand',
      listOfIllConfiguredReasons: 'ListOfIllConfiguredReasons',
      noCategorySelected: 'NoCategorySelected',
      noCategoriesFound: 'NoCategoriesFound',
      couldNotFindStationsbasedOnSearch: 'CouldNotFindStationsbasedOnSearch',
      filterArticlesByTitle: 'FilterArticlesByTitle',
      loadingRefreshingStations: 'LoadingRefreshingStations',
      loadingRefreshingCompetitors: 'LoadingRefreshingCompetitors',
      endDtMustbeEqualOrGreaterThanStartDt: 'EndDtMustbeEqualOrGreaterThanStartDt',
      selectValidDateIn2020OrAfter: 'SelectValidDateIn2020OrAfter',
      loading: 'Loading',
      noDataForSelectedStations: 'NoDataForSelectedStations',
      otrDealer: 'OTRDealer',
      weatherNotAvailable: 'WeatherNotAvailable',
      sessionHasExpired: 'SessionHasExpired',
      loadingRiskSharing: 'LoadingRiskSharing',
      couldNotFindArticleWithTag: 'CouldNotFindArticleWithTag',
      noDealerCommissionResultsFound: 'NoDealerCommissionResultsFound',
      mysteryShopAdminNoStationSelected: 'MysteryShopAdminNoStationSelected',
      noUserActivitiesFound: 'NoUserActivitiesFound',
      mayNotBeDataForAllStations: 'MayNotBeDataForAllStations',
      articleSharedSuccessfully: 'ArticleSharedSuccessfully',
      thisPageIsUnderConstruction: 'ThisPageIsUnderConstruction',
      pdfReportLoading: 'PDFReportLoading',
      couldNotSendServiceTicket: 'CouldNotSendServiceTicket',
      serviceTicketSentSuccessfully: 'ServiceTicketSentSuccessfully',
      pleaseSelectAStation: 'PleaseSelectAStation',
      theUserWasSuccessfullyAdded: 'TheUserWasSuccessfullyAdded',
      sorryWeCouldNotAddTheNewUser: 'SorryWeCouldNotAddTheNewUser',
      theUserWasSuccessfullyUpdated: 'TheUserWasSuccessfullyUpdated',
      sorryWeCouldNotUpdateTheUser: 'SorryWeCouldNotUpdateTheUser',
      areYouSureYouWantToDeleteThisUserFromTheTeam: 'AreYouSureYouWantToDeleteThisUserFromTheTeam',
      areYouSureYouWantToDisableThisUserInTheTeam: 'AreYouSureYouWantToDisableThisUserInTheTeam',
      areYouSureYouWantToEnableThisUserInTheTeam: 'AreYouSureYouWantToEnableThisUserInTheTeam',
      theUserWasSuccessfullyDeleted: 'TheUserWasSuccessfullyDeleted',
      sorryWeCouldNotDeleteTheUser: 'SorryWeCouldNotDeleteTheUser',
      theUserWasSuccessfullyDisabled: 'TheUserWasSuccessfullyDisabled',
      sorryWeCouldNotDisableTheUser: 'SorryWeCouldNotDisableTheUser',
      theUserWasSuccessfullyEnabled: 'TheUserWasSuccessfullyEnabled',
      sorryWeCouldNotEnableTheUser: 'SorryWeCouldNotEnableTheUser',
      noFilesSelected: 'NoFilesSelected',
      doYouNeedToUpdateTeamUser: 'DoYouNeedToUpdateTeamUser',
      noHSEMoments: 'NoHSEMoments',
      onlyImageFileTypesCurrentlySupported: 'OnlyImageFileTypesCurrentlySupported',
      only5AttachmentsAllowed: 'Only5AttachmentsAllowed',
      sorryWeCouldNotDownloadThePDFReport: 'SorryWeCouldNotDownloadThePDFReport',
      couldNotGetStationConfiguration: 'CouldNotGetStationConfiguration',
      couldNotGetPDFReport: 'CouldNotGetPDFReport',
      siteHasNotMadeOTRSelection: 'SiteHasNotMadeOTRSelection',
      currentOTRSelectionOn: 'CurrentOTRSelectionOn',
      currentlySelectedStationIsNotOTR: 'CurrentlySelectedStationIsNotOTR',
      currentlySelectedStationDoesNotHaveCarWash: 'CurrentlySelectedStationDoesNotHaveCarWash',
      promoCodeUpdatedSuccessfully: 'PromoCodeUpdatedSuccessfully',
      thereAreCurrentlyNoCarwashArticles: 'ThereAreCurrentlyNoCarwashArticles',
      emailReportSentSuccessful: 'EmailReportSentSuccessful',
      unableToEmailReport: 'UnableToEmailReport',
      fuelRunoutReportMessage: 'FuelRunoutReportMessage',
      downgradeRunoutReportMessage: 'DowngradeRunoutReportMessage',
      downgradeRunoutReportSubMessage: 'DowngradeRunoutReportSubMessage',
      noArticlesFound: 'NoArticlesFound',
      thereAreNoNotifications: 'ThereAreNoNotifications',
      thereAreNewArticlesForYourStation: 'ThereAreNewArticlesForYourStation'
    },
    navbar: {
      tooltips: {
        language: 'LanguageTooltip',
        search: 'SearchTooltip',
        checklist: 'ChecklistTooltip',
        help: 'HelpTooltip'
      },
      CategoriesNavSection: 'CategoriesNavSection',
      ManageNavSection: 'ManageNavSection',
      HomePage: 'HomePage',
      LinksPage: 'LinksPage',
      internalDocumentationPage: 'InternalDocumentationPage',
      PlanogramsPage: 'PlanogramsPage',
      ReportsPage: 'ReportsPage',
      adminDashboard: 'AdminDashboard',
      manageUsers: 'ManageUsers',
      manageStations: 'ManageStations',
      mysteryShopper: 'MysteryShopper',
      dashboard: 'Dashboard',
      dealerCommissionReportPage: 'DealerCommissionReportPage',
      bulkUpdate: 'BulkUpdate',
      carwash: 'CarWash',
      manageTeam: 'ManageTeam',
      fuelPriceReportPage: 'FuelPriceReportPage',
      surveysPage: 'SurveysPage',
      onTheRun: 'OTRHub'
    },
    pages: {
      errors: {
        notAuthorized: {
          pageTitle: 'PageTitle401',
          errorSummary: 'ErrorSummary401',
          errorDescription: 'ErrorDescription401',
          svgAltDescription: 'SVGAltDescription401'
        },
        notFound: {
          pageTitle: 'PageTitle404',
          errorSummary: 'ErrorSummary404',
          errorDescription: 'ErrorDescription404',
          svgAltDescription: 'SVGAltDescription404'
        },
        serverError: {
          pageTitle: 'PageTitle500',
          errorSummary: 'InternalServerError',
          svgAltDescription: 'SVGAltDescription500'
        },
        articleUnauthorized: {
          pageTitle: 'ArticleUnauthorizedPageTitle',
          errorSummary: 'ArticleUnauthorizedErrorSummary',
          errorDescription: 'ArticleUnauthorizedErrorDescription',
          svgAltDescription: 'ArticleUnauthorizedSVGAltDescription'
        },
      }
    },
    pageTitles: {
      notifications: 'Notifications',
      overview: 'OverviewPage',
      userRegistration: 'User Registration',
      accountSettings: 'AccountSettingsPage',
      safetySecurityCompliance: 'SafetySecurityCompliancePage',
      siteOperations: 'SiteOperationsPage',
      financial: 'FinancialPage',
      customerExperience: 'CustomerExperiencePage',
      marketing: 'MarketingPage',
      merchandising: 'MerchandisingPage',
      training: 'TrainingPage',
      home: 'HomePage',
      forms: 'FormsPage',
      favourites: 'FavouritesPage',
      links: 'LinksPage',
      profile: 'ProfilePage',
      reports: 'ReportsPage',
      criticalImportantUpdate: 'CriticalImportantUpdatePage',
      announcementUpdate: 'AnnouncementPage',
      last7DaysUpdate: 'Last7DaysUpdatePage',
      planogramsPage: 'PlanogramsPage',
      internalDocumentation: 'InternalDocumentationPage',
      help: 'HelpPage',
      faq: 'FAQPage',
      termsAndConditions: 'TermsAndConditionsPage',
      about: 'About',
      fuelRunOut: 'FuelRunOut',
      contactDirectory: 'ContactDirectoryPage',
      whoReadWhatArticles: 'WhoReadWhatArticles',
      whoAcknowledgedWhatArticles: 'WhoAcknowledgedWhatArticles',
      adminDashboard: 'AdminDashboard',
      manageUsers: 'ManageUsers',
      manageStations: 'ManageStations',
      manageTeam: 'ManageTeam',
      dealerCommissionReport: 'DealerCommissionReport',
      userActivityReport: 'UserActivityReport',
      manageTeamReport: 'Team Managed Report',
      articlesWithTag: 'ArticlesWithTag',
      bulkUpdateContent: 'BulkUpdateContent',
      carwashDashboard: 'CarwashDashboard',
      mysteryShopper: 'MysteryShopper',
      shareArticle: 'ShareArticle',
      submitServiceTicket: 'SubmitServiceTicket',
      fuelPriceReport: 'FuelPriceReport',
      surveysPage: 'SurveysPage',
      fuelRunoutReport: 'FuelRunoutReportPage',
      reportAFuelRunout: 'ReportAFuelRunOut',
      downgradeRunoutReport: 'DowngradeRunoutReportPage',
      aldReport: 'ALDReportPage',
      otrHub: 'OTRHub'
    },
    roles: {
      admin: 'Admin',
      territoryManager: 'TerritoryManager',
      super: 'SuperAdmin'
    },
    sectionHeaders: {
      latestOTRContent: 'LatestOTRContent',
      summaryOTR: 'SummaryOTR',
      Announcements: 'AnnouncementsHeader',
      CriticalUpdates: 'CriticalUpdatesHeader',
      latestUpdates: 'LatestUpdatesHeader',
      LocalWeather: 'LocalWeatherHeading',
      MoreCategoriesIn: 'MoreCategoriesIn',
      categories: 'Categories',
      reportStatistics: 'ReportStatistics',
      viewPlanoGrams: 'ViewPlanograms',
      planogramCategories: 'PlanogramCategories',
      attachments: 'Attachments',
      brand: 'Brand',
      site: 'Site',
      address: 'Address',
      operatingModel: 'OperatingModel',
      articles: 'Articles',
      planograms: 'Planograms',
      general: 'General',
      articlesIn: 'ArticlesIn',
      promotionalPeriods: 'PromotionalPeriods',
      scoreOverTime: 'ScoreOverTime',
      scoreComparison: 'ScoreComparison',
      scoreDetails: 'ScoreDetails',
      eventsCalendar: 'EventsCalendar',
      hseMoment: 'HSEMoment',
      carWashArticles: 'CarWashArticles',
      otrOptions: 'OTROptions',
      regular: 'Regular',
      diesel: 'Diesel',
      amount: 'Amount',
      dieselRestricted: 'DieselRestricted',
      featureNotActiveHeaderTable: 'FeatureNotActiveHeaderTable',
      networkIssueHeaderTable: 'NetworkIssueHeaderTable',
    },
  },
  images: {
    parklandLogo: 'Parkland-logo.png',
    parklandWordmarkLogo: 'parkland-wordmark-logo.png',
    gatewayBannerEnglish: 'Gateway English-Banner-150px-x75px.png',
    gatewayBannerFrench: 'Gateway-French-Banner 150px-x75px.png'
  },
  localStorage: {
    defaultLatitude: 'default-gps-latitude',
    defaultLongitude: 'default-gps-longitude',
    // default values are for Ottawa
    defaultLatitudeValue: '45.421532',
    defaultLongitudeValue: '-75.697189',
    userLatitude: 'user-browser-gps-latitude',
    userLongitude: 'user-browser-gps-longitude',
    userSelectedLanguage: 'user-selected-language',
  },
  gatewayAPI: {
    apiName: process.env.REACT_APP_AWS_CLOUD_LOGIC_CUSTOM_NAME,
    mail: {
      defaultToEmail: 'no-reply@parkland.ca',
      helpEmailSubject: 'New Help Request from The Gateway'
    },
    paths: {
      dealer: {
        base: 'dealer',
        fuelPrice: 'fuelprice',
        siteConfiguration: 'siteconfiguration',
        commissionReport: 'GetDealerCommission',
        commissionReportPDF: 'dealercommisionpdf'
      },
      user: {
        base: 'user',
        language: 'language',
        bookmark: 'bookmark',
        stations: 'stations',
        admin: 'admin',
        tm: 'tm',
        super: 'super',
        logging: 'logging',
        activityReport: 'activity',
        team: 'team',
        status: 'status',
      },
      search: {
        base: 'search',
        planograms: 'planograms'
      },
      station: {
        base: 'station',
        byemail: 'byemail',
        history: 'history',
        acknowledge: 'acknowledge',
        getAcknowledge: 'getacknowledge',
        rdoc: 'rdoc',
        dealerCommissionReport: 'dealercommissionreport',
        mysteryShop: 'mysteryshop',
        otrHub: 'otrhub',
        downloadOTRHub: 'downloadotrhub'
      },
      article: {
        base: 'article'
      },
      mail: {
        base: 'mail',
        article: 'article',
        serviceDesk: 'servicedesk',
        fuelRunout: 'fuelrunout',
        downGrade: 'downgradereport'
      },
      report: {
        base: 'report',
        history: 'history',
        getArticlesRead: 'getarticlesread',
        acknowledge: 'acknowledge',
        mysteryShop: 'mysteryshop',
        ageRestricted: 'agerestricted',
        mysteryShopPDF: 'mysteryshoppdf',
        logging: 'logging',
        useracknowledgements: 'useracknowledgements',
        teamManagedReport: 'getreportmanagetm'
      }
    },
    operatingModels: {
      coop: 'COOP-Company owned and operated',
      coro: 'CORO-Company owned retail operated',
      cosa: 'COSA-Company Owned  Stand-Alone',
      dodo: 'DODO-Dealer owned dealer operated',
      franchise: 'FRANCHISE-Franchise',
      na: 'NA-Not Available',
      rdoc: 'RDOC-Retail dealer on consignment',
      codes: {
        coop: 'COOP',
        coro: 'CORO',
        cosa: 'COSA',
        dodo: 'DODO',
        franchise: 'FRANCHISE',
        na: 'NA',
        rdoc: 'RDOC'
      }
    },
    requestheaders: {
      applicationJSON: 'application/json',
      allowedMethods: [
        'GET',
        'POST',
        'PUT',
        'DELETE'
      ],
      allowedHeaders: [
        'Content-Type',
        'Accept',
        // 'Access-Control-Allow-Method',
        // 'Access-Control-Allow-Methods',
        // 'Access-Control-Allow-Headers'
      ]
    }
  },
  picAPI: {
    baseURL: process.env.REACT_APP_DEALER_API_CUSTOM_ENDPOINT,
    paths: {
      getSiteConfiguration: '/Home/GetSiteConfiguration?SiteId=',
      getDealerCommission: '/Home/GetDealerCommission'
    }
  },
  picAWSAPI: {
    apiNameAWS: process.env.REACT_APP_PICAWS_CLOUD_LOGIC_CUSTOM_ENDPOINT,
    apiNamePIC: process.env.REACT_APP_PICAWS_API_CUSTOM_ENDPOINT,
    baseEndPoint: 'Gateway',
    paths: {
      CompetitorSurveys: 'CompetitorSurveys',
      CompetitorsToSurveyBySiteId: 'CompetitorsToSurveyBySiteId',
      Surveys: 'Surveys'
    }
  },
  routes: {
    article: '/article',
    articleWithQS: '/article?id={id}',
    articles: '/articles',
    articlesWithQS: '/articles?category={category}',
    categoriesWithQS: '/articles?mainCategory={mainCategory}&subCategory={subCategory}',
    favourites: '/favourites',
    reports: '/reports',
    notFound: '/404',
    notAuthorised: '/401',
    serverError: '/500',
    articleUnauthorized: '/articleunauthorized',
    whoreadwhatarticles: '/whoreadwhatarticles',
    quialuquelsarticles: '/quialuquelsarticles',
    whoacknowledgedwhatarticles: '/whoacknowledgedwhatarticles',
    quiareconnuquelsarticles: '/quiareconnuquelsarticles',
    dealerCommissionReport: '/dealercommissionreport',
    userActivityReport: '/useractivityreport',
    help: '/help',
    faq: '/help/faq',
    termsAndConditions: '/help/termsandconditions',
    submitServiceTicket: '/submitserviceticket',
    fuelRunoutReport: '/fuelrunoutreport',
    downgradeRunoutReport: '/downgraderunoutreport',
    fuelPriceReport: '/fuelpricereport',
    manageTeamReport: '/manageteamreport',
    aldReport: '/aldreport',
    home: '/',
    surveys: '/surveys'
  },
  values: {
    nonApplicable: 'N/A'
  },
  weather: {
    openWeatherAPIKey: '41aadc8c9e59ee4dfa04dddf2fe4f084',
    openWeatherURL: 'https://api.openweathermap.org/data/2.5/onecall?appid=[APP_ID]&lang=[LANG]&units=metric&lat=[LATITUDE]&lon=[LONGITUDE]',
    urlParams: {
      appID: '[APP_ID]',
      language: '[LANG]',
      latitude: '[LATITUDE]',
      longitude: '[LONGITUDE]',
    }
  }
};
export const contentfulConfig = {
  accessToken: process.env.REACT_APP_CONTENTFUL_DELIVERYAPI_ACCESSTOKEN,
  baseApiUri: 'https://cdn.contentful.com/',
  space_id: process.env.REACT_APP_CONTENTFUL_SPACEID,
  environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT,
  query: {
    keys: {
      articleContentType: 'sys.contentType.sys.id'
    },
    values: {
      article: 'stationPortalArticle',
      allRegionsCode: 'AR',
      allRegionsEn: 'All Regions',
      allRegionsFr: 'Toutes les régions',
      allBrandsCode: 'AB',
      allBrandsEn: 'All Brands',
      allBrandsFr: 'Toutes les marques',
      allDivisions: 'All Divisions'
    }
  }
};

export const amplifyConfig = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_REDIRECT_SIGNOUT,
  oauth: {
    domain: process.env.REACT_APP_OAUTH_DOMAIN,
    scope: process.env.REACT_APP_OAUTH_SCOPE.split(','),
    redirectSignIn: process.env.REACT_APP_REDIRECT_SIGNIN,
    redirectSignOut: process.env.REACT_APP_REDIRECT_SIGNOUT,
    redirect_uri: process.env.REACT_APP_REDIRECT_SIGNOUT,
    responseType: process.env.REACT_APP_OAUTH_RESPONSE_TYPE
  },
  federationTarget: process.env.REACT_APP_OAUTH_FEDERATION_TARGET,
  aws_dynamodb_all_tables_region: process.env.REACT_APP_AWS_DYNAMODB_ALL_TABLES_REGION,
  aws_dynamodb_table_schemas: [
    {
      tableName: process.env.REACT_APP_AWS_DYNAMODB_TABLE_SCHEMAS_TABLENAME,
      region: process.env.REACT_APP_AWS_DYNAMODB_TABLE_SCHEMAS_REGION
    }
  ],
  aws_cloud_logic_custom: [
    {
      name: process.env.REACT_APP_AWS_CLOUD_LOGIC_CUSTOM_NAME,
      endpoint: process.env.REACT_APP_AWS_CLOUD_LOGIC_CUSTOM_ENDPOINT,
      region: process.env.REACT_APP_AWS_CLOUD_LOGIC_CUSTOM_REGION
    }
  ]
};
